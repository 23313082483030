import React from "react";
import { Stack, Divider } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function SectionBreak(props: {text: string|any[]}){
    const {text} = props;
    return (
        <Stack direction="column" textAlign="center" spacing={2}>
        <Divider sx={{mt: 4}}/>
        <h4>{text}</h4>
        <Divider/>
        </Stack>
    );
}